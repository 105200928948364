import { useCallback, useState } from 'react'

import { InputField } from 'components/InputField'
import { VouchersDataTypes } from 'types/vouchers'

const Index = ({
  modalClose,
  selectedRowData,
}: {
  modalClose: () => void
  selectedRowData: VouchersDataTypes
}): JSX.Element => {
  const [stores, storesSet] = useState<string[]>(selectedRowData.redeemableAt)
  const [storesBackup, _] = useState<string[]>(selectedRowData.redeemableAt)

  const onSearch = useCallback(
    (searchParam: string) => {
      const result = storesBackup.filter((s) =>
        s.toLowerCase().includes(searchParam)
      )
      if (result.length) {
        storesSet(result)
      } else {
        storesSet(storesBackup)
      }
    },
    [stores, storesBackup, storesSet]
  )

  return (
    <div>
      <div>
        <InputField
          type="input"
          name="search"
          label={`Search ${storesBackup.length} Stores`}
          containerClassName="flex flex-col flex-grow"
          onChange={(e) => onSearch(e.target.value)}
          rounded
        />
      </div>
      <div></div>
      <br />
      <ul className="flex flex-col gap-1">
        {stores.slice(0, 10).map((item) => (
          <li
            key={item}
            className="w-full capitalize tracking-wider bg-gray-50"
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Index
